import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

gsap.registerPlugin(ScrollTrigger);

const ServiceSection = () => {
  const creativeRef = useRef(null);
  const marketingRef = useRef(null);

  const data = useStaticQuery(graphql`
    query {
      createImage: file(relativePath: { eq: "create.webp" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 1200
          )
        }
      }
    }
  `);

  const createImage = getImage(data.createImage);

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);

    gsap.fromTo(
      creativeRef.current.querySelector('.content-wrapper'),
      { yPercent: 100 },
      {
        yPercent: 0,
        duration: 1.5,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: creativeRef.current.querySelector('.content-wrapper'),
          start: 'top 95%',
          end: 'top 60%',
          toggleActions: 'play none none none',
        },
      }
    );

    gsap.fromTo(
      marketingRef.current.querySelector('.content-wrapper'),
      { yPercent: 100 },
      {
        yPercent: 0,
        duration: 1.5,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: marketingRef.current.querySelector('.content-wrapper'),
          start: 'top 95%',
          end: 'top 60%',
          toggleActions: 'play none none none',
        },
      }
    );
  }, []);

  return (
    <>
      {/* CREATIVE（黒背景） */}
      <section
        ref={creativeRef}
        className="relative bg-stone-900 py-32 flex justify-center items-center w-full overflow-hidden"
      >
        <div className="content-wrapper relative w-full px-6 md:px-20 max-w-screen-2xl mx-auto flex flex-col lg:flex-row items-center justify-center gap-8 lg:gap-16">
          <div className="lg:w-2/5 w-full text-left z-10 lg:pl-20">
            <h3 className="text-4xl md:text-6xl font-bold text-white tracking-wide">
              Web制作・開発
            </h3>
            <p className="text-2xl text-gray-300 mt-4 md:mt-6 leading-loose">
              ウェブサイト制作に必要な
              <strong>
                デザイン・映像制作・ライティング（文章作成）・システム開発
              </strong>
              を
              一貫して行える当事務所の制作事業です。デザインとシステムの力でブランドの価値を最大限に引き出し、
              ユーザーに<strong>強く印象付けるウェブサイト</strong>
              を提供します。
            </p>
            {/* 詳しく見るボタン */}
            <a
              href="/service#creative"
              className="mt-6 inline-flex items-center bg-white text-stone-900 px-6 py-3 md:px-10 md:py-5 rounded-lg text-lg md:text-2xl font-semibold shadow-md hover:bg-gray-300 transition duration-300"
            >
              詳しく見る
              <span className="ml-3 text-2xl md:text-3xl">→</span>
            </a>
          </div>

          {/* 画像 + 背後のボックス（センターから引っ込める & 角丸統一） */}
          <div className="lg:w-3/5 w-full flex items-center lg:justify-end relative">
            {/* 背後のボックス（センターに食い込まないように調整） */}
            <div className="absolute top-6 left-12 w-[95%] h-full bg-gray-700 opacity-50 rounded-xl shadow-lg"></div>
            {/* メイン画像（角丸統一） */}
            <GatsbyImage
              image={createImage}
              alt="Creative"
              className="relative w-full lg:max-w-[90%] h-auto object-cover rounded-xl shadow-2xl"
            />
          </div>
        </div>
      </section>

      {/* MARKETING（白背景） */}
      <section
        ref={marketingRef}
        className="relative bg-white py-32 flex justify-center items-center w-full overflow-hidden"
      >
        <div className="content-wrapper relative w-full px-6 md:px-20 max-w-screen-2xl mx-auto flex flex-col lg:flex-row-reverse items-center justify-center gap-8 lg:gap-16">
          <div className="lg:w-2/5 w-full text-left z-10 lg:pr-20">
            <h3 className="text-4xl md:text-6xl font-bold text-stone-900 tracking-wide">
              Webマーケティング
            </h3>
            <p className="text-2xl text-gray-700 mt-4 md:mt-6 leading-loose">
              デジタルマーケティング戦略、SEO対策、広告運用、データ解析を通じて、企業の成長をサポートします。
              目的に合わせた<strong>戦略立案と実行</strong>
              を行い、売上や集客の向上に貢献します。
            </p>
            {/* 詳しく見るボタン */}
            <a
              href="/service#marketing"
              className="mt-6 inline-flex items-center bg-stone-900 text-white px-6 py-3 md:px-10 md:py-5 rounded-lg text-lg md:text-2xl font-semibold shadow-md hover:bg-stone-800 transition duration-300"
            >
              詳しく見る
              <span className="ml-3 text-2xl md:text-3xl">→</span>
            </a>
          </div>

          {/* 動画 + 背後のボックス（センターから引っ込める & 角丸統一） */}
          <div className="lg:w-3/5 w-full flex items-center lg:justify-start relative">
            {/* 背後のボックス（センターに食い込まないように調整） */}
            <div className="absolute top-6 right-12 w-[95%] h-full bg-gray-300 opacity-50 rounded-xl shadow-lg"></div>
            {/* メイン動画（角丸統一） */}
            <video
              src="/videos/marketing.mp4"
              autoPlay
              loop
              muted
              playsInline
              className="relative w-full lg:max-w-[90%] h-auto object-cover rounded-xl shadow-2xl"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceSection;
