import React, { useEffect, useState } from 'react';

const LoadingScreen = ({ onFinish }) => {
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const isFirstVisit = sessionStorage.getItem('visited');

    if (!isFirstVisit) {
      sessionStorage.setItem('visited', 'true');

      setTimeout(() => {
        setIsFadingOut(true); // ✅ まずフェードアウト開始
      }, 1000);

      setTimeout(() => {
        setIsVisible(false); // ✅ フェードアウト完了後に非表示
        onFinish();
      }, 1800);
    } else {
      setIsVisible(false); // ✅ 2回目以降は即非表示
      onFinish();
    }
  }, [onFinish]);

  if (!isVisible) return null; // ✅ 完全に非表示にする

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full bg-black flex items-center justify-center z-50 transition-opacity duration-700 ${
        isFadingOut ? 'opacity-0' : 'opacity-100'
      }`}
      style={{
        pointerEvents: isFadingOut ? 'none' : 'auto', // ✅ フェードアウト中のクリックを防ぐ
      }}
    >
      <p className="text-white text-4xl md:text-6xl font-roboto text-center">
        involve you,
        <br />
        involve us
      </p>
    </div>
  );
};

export default LoadingScreen;
