import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ThoughtSection = () => {
  const thoughtRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setTimeout(() => {
      const ctx = gsap.context(() => {
        if (
          !titleRef.current ||
          !subtitleRef.current ||
          !textRef.current ||
          !thoughtRef.current
        )
          return;

        gsap.fromTo(
          [titleRef.current, subtitleRef.current, textRef.current],
          { x: 100, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 1.5,
            ease: 'power4.out',
            stagger: 0.3,
            scrollTrigger: {
              trigger: thoughtRef.current,
              start: 'top 85%',
              end: 'top 30%',
              scrub: 0.5,
            },
          }
        );
      });

      return () => ctx.revert();
    }, 100);
  }, []);

  return (
    <section
      id="thought"
      ref={thoughtRef}
      className="max-w-screen-2xl w-full px-16 mx-auto py-32 bg-white bg-opacity-90"
    >
      <h1 className="text-xl sm:text-2xl md:text-4xl font-bold text-center text-gray-900 mb-6">
        奈良県のホームページ制作 インヴォルブ
      </h1>
      <h2
        ref={titleRef}
        className="font-roboto text-[5rem] md:text-[10rem] sm:text-[5rem] font-bold text-center tracking-tighter leading-none"
      >
        involve you,
        <br /> involve us
      </h2>
      <strong
        ref={subtitleRef}
        className="block text-[2rem] md:text-[4rem] sm:text-[2rem] font-bold text-center mb-10"
      >
        巻き込みたい、巻き込まれたい
      </strong>
      <p
        ref={textRef}
        className="w-full sm:w-4/5 md:w-4/5 text-[1.4rem] md:text-[2rem] sm:text-[1.4rem] leading-relaxed mx-auto"
      >
        私たちインヴォルブは、WEB制作を通じて、人に届き、人に響き、人と人を繋ぐ体験をインターネット上で実現します。お客さまが伝えたい想いを、届けるべき人にしっかり届け、多くの人々を巻き込みながら共に成長する。その物語に私たち自身も巻き込まれたいと願っています。
      </p>
    </section>
  );
};

export default ThoughtSection;
