import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const FAQPreview = () => {
  const faqs = [
    {
      q: 'インヴォルブは何ができますか？',
      a: 'インヴォルブは、ホームページ制作、システムの開発、インターネットを使った集客をサポートする事務所です。サイトのデザインや構築はもちろん、検索対策や広告運用、運営のお手伝いまで対応します。',
    },
    {
      q: 'インヴォルブはどこの事務所ですか？',
      a: '奈良県橿原市にあるホームページ制作事務所です。',
    },
    {
      q: 'どの地域の案件に対応していますか？',
      a: '当事務所は奈良に拠点を構えておりますが、大阪・京都・和歌山・三重・兵庫・滋賀のお客様にも対応実績がございます。対面でのお打ち合わせも可能ですので、お気軽にご相談ください。',
    },
    {
      q: 'ホームページ制作の費用はどれくらいかかりますか？',
      a: '制作費用は30万円～60万円程度が一般的な目安です。',
    },
    {
      q: '納期はどれくらいですか？',
      a: '通常、2ヶ月〜3ヶ月程度で納品可能です。',
    },
    {
      q: '制作後のサポートはありますか？',
      a: '運用・保守プランもご用意しておりますので、ご相談ください。',
    },
  ];

  // 開いている質問を管理
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion((prev) => (prev === index ? null : index));
  };

  return (
    <div className="bg-stone-800 text-white py-16 sm:py-20 md:py-32">
      <div className="max-w-screen-lg w-full px-8 sm:px-12 md:px-16 mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12">よくある質問</h2>

        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="mb-8 pb-6 border-b border-gray-600">
              <button
                onClick={() => toggleQuestion(index)}
                className="w-full flex justify-between items-center text-left text-2xl font-medium py-6 px-6 bg-stone-700 rounded-lg focus:outline-none hover:bg-stone-600 transition-all"
              >
                <span className="flex items-center gap-3 flex-1 min-w-0">
                  <span className="text-3xl font-bold text-white flex-shrink-0">
                    Q
                  </span>
                  <span className="break-words">{faq.q}</span>
                </span>
                <span className="w-10 h-10 flex items-center justify-center bg-white text-stone-900 text-3xl rounded-full">
                  {openQuestion === index ? (
                    <AiOutlineMinus />
                  ) : (
                    <AiOutlinePlus />
                  )}
                </span>
              </button>

              <div
                className={`transition-all duration-300 overflow-hidden ${
                  openQuestion === index
                    ? 'max-h-60 opacity-100 p-6'
                    : 'max-h-0 opacity-0'
                }`}
              >
                <p className="text-white text-2xl leading-relaxed">{faq.a}</p>
              </div>
            </div>
          ))}
        </div>

        {/* MOREボタンのみ */}
        <div className="mt-6 text-center">
          <a
            href="/faq"
            className="inline-flex items-center bg-white text-stone-900 px-6 py-4 md:px-12 md:py-6 rounded-lg text-2xl md:text-2xl font-semibold shadow-md hover:bg-gray-200 transition duration-300"
          >
            よくある質問をもっと見る
          </a>
        </div>
      </div>
    </div>
  );
};

export default FAQPreview;
