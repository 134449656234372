import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import HeroVideo from '../components/index/HeroVideo';
import ThoughtSection from '../components/index/ThoughtSection';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import ServiceSection from '../components/index/ServiceSection';
import LoadingScreen from '../components/index/LoadingScreen';
import FAQPreview from '../components/index/FAQPreview';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/swiper-custom.css';
import { Navigation, Pagination } from 'swiper/modules';
import { Helmet } from 'react-helmet';
import { FaUser, FaClock, FaTags } from 'react-icons/fa';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const IndexPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const MAX_NEWS_COUNT = 3;
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPage(limit: 4, sort: { publishDate: DESC }) {
        nodes {
          id
          title
          slug
          publishDate(formatString: "YYYY年MM月DD日")
          updatedAt(formatString: "YYYY年MM月DD日") # ✅ 更新日を取得
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              width: 600
            )
          }
          blogTags {
            # ✅ タグを取得
            name
          }
          author {
            # ✅ 著者を取得
            name
          }
        }
      }
      allContentfulNews(limit: 3, sort: { publishDate: DESC }) {
        nodes {
          contentful_id
          title
          slug
          publishDate
        }
      }
    }
  `);

  const posts = data.allContentfulBlogPage.nodes;
  const newsItems = data.allContentfulNews.nodes;

  const title = '奈良のホームページ制作なら｜インヴォルブ';
  const description =
    '私たちインヴォルブは、WEB制作を通じて、人に届き、人に響き、人と人を繋ぐ体験をインターネット上で実現します。お客さまが伝えたい想いを、届けるべき人にしっかり届け、多くの人々を巻き込みながら共に成長する。その物語に私たち自身も巻き込まれたいと願っています。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
  ];

  return (
    <>
      {isLoading ? (
        <LoadingScreen onFinish={() => setIsLoading(false)} />
      ) : (
        <Layout>
          <BreadcrumbSchema breadcrumbList={breadcrumbList} />
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://www.involve-in.jp/" />
            <link
              rel="icon"
              type="image/x-icon"
              href="/images/common/favicon.ico"
            />

            {/* Open Graph (OGP: Facebook, LinkedIn) */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.involve-in.jp/" />
            <meta
              property="og:image"
              content="https://www.involve-in.jp/images/common/ogp.png"
            />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta
              name="twitter:image"
              content="https://www.involve-in.jp/images/common/ogp.png"
            />
          </Helmet>

          <HeroVideo />

          <ThoughtSection />
          <div className="opacity-0 animate-fade-in">
            <section
              id="news"
              className="py-16 bg-white bg-opacity-90 max-w-[900px] mx-auto"
            >
              <div className="container mx-auto px-4">
                {/* 見出しを大きく */}
                <h2 className="font-roboto text-8xl font-bold tracking-wide mb-8 text-black">
                  NEWS
                </h2>

                {/* ニュースリスト */}
                <ul className="list-none p-0 m-5">
                  {newsItems.slice(0, MAX_NEWS_COUNT).map((news) => (
                    <li
                      key={news.contentful_id}
                      className="flex items-center justify-start py-4 border-b border-black/20"
                    >
                      {/* 日付（フォントサイズアップ） */}
                      <span className="bg-black/10 text-2xl text-black px-3 py-1 rounded-full min-w-[80px] text-center">
                        {new Date(news.publishDate)
                          .toLocaleDateString('ja-JP', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                          .replace(/\//g, ' / ')}
                      </span>

                      {/* コンテンツ（タイトル大きく） */}
                      <div className="flex flex-col gap-1 ml-10">
                        <a
                          href={`/news/${news.slug}`}
                          className="text-2xl text-black font-bold  hover:decoration-gray-500"
                        >
                          {news.title}
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>

                {/* もっと見るボタン */}
                {newsItems.length >= MAX_NEWS_COUNT && (
                  <div className="text-center mt-8">
                    <a
                      href="/news/"
                      className="inline-block border border-black/30 text-2xl text-black font-bold py-3 px-8 rounded-lg transition-all duration-300 hover:bg-black hover:text-white"
                    >
                      すべてのニュースを見る
                    </a>
                  </div>
                )}
              </div>
            </section>

            <ServiceSection />
            <FAQPreview />

            <section id="blog" className="py-24 bg-white bg-opacity-50">
              <div className="container mx-auto px-8 max-w-screen-2xl">
                {/* 見出し */}
                <h2 className="font-roboto text-8xl font-bold text-gray-900 mb-12 text-center">
                  Blog
                </h2>
                {/* スマホ時に Swiper 適用 */}
                <div className="md:hidden">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    navigation={true} // ✅ 矢印ボタンを有効化
                    modules={[Navigation, Pagination]}
                  >
                    {posts.slice(0, 4).map((post) => (
                      <SwiperSlide key={post.id}>
                        <div className="bg-white bg-opacity-80 rounded-3xl p-8 shadow-lg transition transform hover:shadow-2xl">
                          {/* 画像 */}
                          <div className="w-full aspect-[4/3] overflow-hidden rounded-xl">
                            <GatsbyImage
                              image={getImage(post.image)}
                              alt={post.title}
                              className="w-full h-full object-cover rounded-xl"
                            />
                          </div>

                          {/* テキストエリア */}
                          <div className="w-full flex flex-col justify-between mt-6">
                            <a
                              href={`/blog/${post.slug}`}
                              className="text-3xl font-bold text-gray-900 hover:underline"
                            >
                              {post.title}
                            </a>

                            {/* タグ */}
                            {post.blogTags && (
                              <div className="flex flex-wrap gap-3 mt-4">
                                {post.blogTags.map((tag) => (
                                  <span
                                    key={tag.name}
                                    className="border-2 border-black text-black text-xl font-medium px-4 py-2 rounded-full flex items-center gap-2"
                                  >
                                    <FaTags className="text-gray-500 text-lg" />{' '}
                                    {tag.name}
                                  </span>
                                ))}
                              </div>
                            )}

                            {/* 変更日 & 著者 */}
                            <div className="flex flex-col text-gray-700 text-lg mt-4 gap-2">
                              <div className="flex items-center gap-2">
                                <FaClock className="text-gray-500 text-2xl" />
                                <span>更新日: {post.updatedAt}</span>
                              </div>
                              <div className="flex items-center gap-2">
                                <FaUser className="text-gray-500 text-2xl" />
                                <span>{post.author.name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                {/* PC時（従来のデザイン） */}
                <div className="hidden md:block">
                  <div className="grid grid-cols-2 gap-12">
                    {posts.slice(0, 4).map((post) => (
                      <div
                        key={post.id}
                        className="flex flex-col md:flex-row bg-white bg-opacity-80 rounded-3xl p-8 shadow-lg transition transform hover:shadow-2xl"
                      >
                        <div className="w-full md:w-1/3 aspect-[4/3] overflow-hidden rounded-xl">
                          <GatsbyImage
                            image={getImage(post.image)}
                            alt={post.title}
                            className="w-full h-full object-cover rounded-xl"
                          />
                        </div>
                        <div className="w-full md:w-2/3 md:pl-8 flex flex-col justify-between mt-6 md:mt-0">
                          <a
                            href={`/blog/${post.slug}`}
                            className="text-3xl font-bold text-gray-900 hover:underline"
                          >
                            {post.title}
                          </a>
                          {post.blogTags && (
                            <div className="flex flex-wrap gap-3 mt-4">
                              {post.blogTags.map((tag) => (
                                <span
                                  key={tag.name}
                                  className="border-2 border-black text-black text-xl font-medium px-4 py-2 rounded-full flex items-center gap-2"
                                >
                                  <FaTags className="text-gray-500 text-lg" />{' '}
                                  {tag.name}
                                </span>
                              ))}
                            </div>
                          )}
                          <div className="flex flex-col md:flex-row items-start md:items-center text-gray-700 text-lg mt-4 gap-2 md:gap-6">
                            <div className="flex items-center gap-2">
                              <FaClock className="text-gray-500 text-2xl" />
                              <span>更新日: {post.updatedAt}</span>
                            </div>
                            <div className="flex items-center gap-2">
                              <FaUser className="text-gray-500 text-2xl" />
                              <span>{post.author.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* 他の記事を読むボタン */}
                <div className="text-center mt-16">
                  <a
                    href="/blog/"
                    className="inline-block border border-black text-2xl font-bold text-black py-4 px-10 rounded-xl transition duration-300 hover:bg-black hover:text-white"
                  >
                    他の記事を読む
                  </a>
                </div>
              </div>
            </section>
            <Breadcrumbs breadcrumbList={breadcrumbList} />
          </div>
        </Layout>
      )}
    </>
  );
};

export default IndexPage;
