import React, { useState, useEffect, useRef } from 'react';

const HeroVideo = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);
  const videoRefSp = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (videoRef.current) {
              videoRef.current
                .play()
                .catch((error) =>
                  console.error('PC Video autoplay failed:', error)
                );
            }
            if (videoRefSp.current) {
              videoRefSp.current
                .play()
                .catch((error) =>
                  console.error('SP Video autoplay failed:', error)
                );
            }
            setVideoLoaded(true);
            observer.disconnect(); // ✅ 一度ロードしたら監視を解除
          }
        });
      },
      { threshold: 0.3 } // ✅ 30%表示されたらロード開始
    );

    if (videoRef.current) observer.observe(videoRef.current);
    if (videoRefSp.current) observer.observe(videoRefSp.current);

    return () => observer.disconnect();
  }, []);

  return (
    <section id="mv" className="relative w-full h-screen overflow-hidden">
      {/* ✅ PC用 */}
      <div className="video-wrap hidden md:block relative w-full h-full">
        {!videoLoaded && (
          <img
            src="/images/index/placeholder_pc.webp"
            alt="PC用プレースホルダー"
            className="absolute inset-0 w-full h-full object-cover"
          />
        )}
        <video
          key="pc-video" // ✅ GatsbyのSSRで再レンダリングを確実に適用
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          preload="none"
          fetchpriority="low"
          className={`absolute w-full h-full object-cover transition-opacity duration-500 ${
            videoLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          onLoadedData={() => setVideoLoaded(true)}
        >
          <source src="/videos/mv.mp4" type="video/mp4" />
        </video>
        <div className="video-overlay absolute inset-0 bg-black opacity-50"></div>
        <div className="dot-overlay absolute inset-0 bg-dot-pattern"></div>
      </div>

      {/* ✅ スマホ用 */}
      <div className="video-wrap block md:hidden relative w-full h-full bg-black overflow-hidden">
        {!videoLoaded && (
          <img
            src="/images/index/placeholder_sp.webp"
            alt="SP用プレースホルダー"
            className="absolute inset-0 w-full h-full object-cover"
          />
        )}
        <video
          ref={videoRefSp}
          autoPlay
          loop
          muted
          playsInline
          preload="none"
          fetchpriority="low"
          className="absolute inset-0 w-full h-full object-contain transition-opacity duration-500"
          onLoadedData={() => setVideoLoaded(true)}
        >
          <source src="/videos/mv_sp.mp4" type="video/mp4" />
        </video>
        <div className="video-overlay absolute inset-0 bg-black opacity-50"></div>
        <div className="dot-overlay absolute inset-0 bg-dot-pattern"></div>
      </div>
    </section>
  );
};

export default HeroVideo;
